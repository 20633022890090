import * as React from 'react';

import { useQuery } from '@apollo/client';
import { RouteComponentProps } from 'react-router';
import { Container } from '../../../layout/Container';
import { Heading } from '../../../ui/text/Heading';
import { Section } from '../../../layout/Section';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { NewsCard, NewsListWrapper } from '../../../ui/cards/News';

import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { Pagination } from '../../../ui/pagination/Pagination';
import { Seo } from '../../../../utils/seo/Index';
import { FormValuation } from './FormValuation';
import { Paragraph } from '../../../ui/text/Paragraph';

import { OFFICE_NEWS_QUERY } from '../../../../../client/__graphql__/queries/office/news/news';

interface IRoute {
  urltag: string;
}

const News: React.FC<RouteComponentProps<IRoute>> = ({ match, location }) => {
  const [page, setPage] = React.useState(0);
  const { data, loading } = useQuery(OFFICE_NEWS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        urltag: match?.params?.urltag,
        page: page
      }
    }
  });

  const { fadeIn } = useFadeTransition({
    loaded: !loading
  });

  return (
    <>
      <Seo
        title="Nyheter - PrivatMegleren - Nordeas Eiendomsmeglerkjede"
        description="Se våre nyheter"
        url={location.pathname}
      />
      <Container
        style={{ paddingTop: '100px', minHeight: '1000px', ...fadeIn }}
      >
        <Section className="center">
          <Heading tag="h1">Nyheter</Heading>
          {data?.getAllNews?.list.length === 0 ? (
            <Paragraph center={true}>Ingen nyheter funnet.</Paragraph>
          ) : null}
        </Section>
        <NewsListWrapper>
          {data?.getAllNews?.list.map((item: any, index: number) => {
            return (
              <NewsCard
                key={`news_${index}`}
                title={item.title}
                ingress={item.ingress}
                image={item.image}
                slug={`${location.pathname}/${item.slug}`}
                currentPage={page}
              />
            );
          })}
        </NewsListWrapper>
        {data?.getAllNews?.list?.length ? (
          <Pagination
            center={true}
            currentPage={page}
            maxPages={data?.getAllNews?.total}
            total={data?.getAllNews?.total}
            itemsPerPage={24}
            pageOnChange={({ selected }) => {
              setPage(selected);
              if (typeof window !== 'undefined') {
                window.scroll(0, 0);
              }
            }}
          />
        ) : null}
        <FormValuation urltag={match?.params?.urltag} />
      </Container>
      <BackgroundLoad
        opacity={0.3}
        position="center"
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
      />
    </>
  );
};

export default News;
